















































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { LogItem } from './types'

@Component({ name: 'LogManage' })
export default class LogManage extends VueBase {
  private deleteDialogOpen = false
  private clearDialogOpen = false
  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<LogItem> = []
  private selectIdSet: Array<number> = []
  private selectAllPage = false

  created() {
    this.getTableData()
  }

  private selectIdChange(id: number) {
    const curSet = new Set([...this.selectIdSet])
    if (curSet.has(id)) {
      curSet.delete(id)
    } else {
      curSet.add(id)
    }
    this.selectIdSet = [...curSet]
  }

  private selectAllChange() {
    if (this.selectAllPage) {
      this.selectIdSet = this.tableData.map((item: LogItem) => {
        return item.log_id
      })
    } else {
      this.selectIdSet = []
    }
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: this.pageSize,
    }
    this.loadingStart()
    const { status, msg, data, total } = await this.services.setting.logList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.selectIdSet = []
    this.tableData = data
    this.total = total
  }

  private deleteDialogShow() {
    if (this.selectIdSet.length <= 0) {
      this.$message({
        showClose: true,
        message: this.$t('log.choseLog') as string,
        type: 'error',
      })
      return
    }
    this.deleteDialogOpen = true
  }
  private async logDelete() {
    const params = {
      ids: this.selectIdSet.join(','),
    }
    const { status, msg } = await this.services.setting.logDelete(params)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
    this.deleteDialogOpen = false
    this.selectAllPage = false
    await this.getTableData()
  }
  private async logClear() {
    const { status, msg } = await this.services.setting.logClear()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
    this.clearDialogOpen = false
    await this.getTableData()
  }
  private async logExport() {
    if (this.selectIdSet.length <= 0) {
      this.$message({
        showClose: true,
        message: this.$t('log.choseLog') as string,
        type: 'error',
      })
      return
    }
    window.open(`/api/v1/log/export?ids=${this.selectIdSet.join(',')}`)
  }
}
